@font-face {
  font-family: 'OneMobile';
  font-weight: 300;
  src: url('../assets/font/ONE_Mobile_Light.ttf');
}
@font-face {
  font-family: 'OneMobile';
  font-weight: 400;
  src: url('../assets/font/ONE_Mobile_Regular.ttf');
}
@font-face {
  font-family: 'OneMobile';
  font-weight: 600;
  src: url('../assets/font/ONE_Mobile_Bold.ttf');
}
